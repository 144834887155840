@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;700&display=swap');

body {
  color: white;
  margin: 0;
  font-family: 'Outfit', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html, body{
  width: 100%;
  height: 100%;
  background: linear-gradient(270deg, #fa392d 0%,#fac20e 100%);
  text-align: center;
}
h1,h2 {
  padding-left: 10px;
  padding-right: 10px;
}
h1 {
  font-weight: 700;
}
h2 {
  font-weight: 300;
}
h3{
  font-size: 1.4em;
}
p {
  padding: 10px 20px;
  text-align: left;
  font-size: 20px;
  line-height: 30px;
}
.container {
  margin-bottom: 20px;
  max-width: 800px;
  margin: 0px auto;
}
.bgLogo{
  position: absolute;
  left: 40px;
  top: 60px;
  border-radius: 50px;
  width:100px;
}
a {
  outline: none;
  text-decoration: none;
  padding: 0.2em;
  padding-top: 0em;
  border-radius: 5px;
  background: linear-gradient(to bottom, #7d7e7d 0%,#0e0e0e 100%);
}
a:link {
  color: #fff;
}

a:visited {
  color: #fff;
}

a:focus,a:hover, a:active  {
  outline: 1px solid #fff;
}
.clearAll {
  -webkit-appearance: none;
  text-align: center;
  background-color:#e91e63;
  color:white;
  outline: none;
  border:0px;
  padding:10px; 
  border-radius: 10px;
  font-size: 18px;
}
.searchbar {
  background: linear-gradient(to bottom, #7d7e7d 0%,#0e0e0e 100%);
  border-bottom: 2px  solid rgb(145, 142, 142);
  padding: 30px 0px;
  padding-top: 10px;
  margin-bottom: 50px;
}
.searchbar input[type="text"] {
  -webkit-appearance: none;
  background-color:rgb(169, 169, 169);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  color:rgb(55, 55, 55);
  padding:10px; 
}
.searchbar input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: none;
  background-color:#e91e63;
  color:white;
  font-weight: 600;
  padding:10px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 2px 0px 10px #fb0054;
}
.searchbar input {
  font-size: 30px;
  outline: none;
  border:0px;
}
.legend {
  color:white;  
  text-align: left;
  left: 50px;
  top: 30px;
}
.legend .rv-discrete-color-legend-item { 
  color: white;
  font-size: 18px;
  font-weight: 600;
}
.loaderContainer {
  position: absolute;
  z-index:2;
  width:50px;
  height: 50px;
  left: 50%;
  top:50%;
  margin-left: -25px;
  margin-top: -25px;
}
.loaderContainer img {
  width: 100%;
  height: 100%;
}

.banner-placeholder{
  height: 200px;
}

.social-share button {
  margin: 0px 10px
}

.topListGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  list-style: none;
  padding: 0px;
}
.topListGrid li {
  margin: 15px 0px;
  cursor: pointer;  
}
.topListGrid li a {
  padding: 5px;
  font-size: 1.1rem;
  font-weight: 600;
}
@media only screen and (max-width: 767px){ 
  .bgLogo{
    position:relative;
    top: 10px;
    left: auto;
    border-radius: 50px;
    width:75px;
  }
  .searchbar {
    background: linear-gradient(to bottom, #7d7e7d 0%,#0e0e0e 100%);
    border-bottom: 2px  solid rgb(145, 142, 142);
    padding: 20px 0px;
    padding-top: 5px;
    margin-bottom:20px;
  }
  .searchbar input[type="text"] {
    -webkit-appearance: none;
    background-color:rgb(169, 169, 169);
    border-radius: 10px;
    height: 30px;
    padding:5px;
  }
  .searchbar input[type="submit"] {
    -webkit-appearance: none;
    background-color:#e91e63;
    border-radius: 10px;
  }
  .searchbar input {
    font-size: 20px;
  }
  .legend .rv-discrete-color-legend-item { 
    font-size: 16px;
  }
  .banner-placeholder{
    height: 100px;
  }
  .clearAll {
    padding:6px; 
    font-size: 14px;
  }
}